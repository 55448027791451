import React from 'react'

const YearSection = ({ from , to}) => {
  return (
    <div>
        <div className="w-full   text-violet-400 text-5xl sm:text-9xl font-light font-['Canela'] leading-[166.40px]">{from} - {to}</div>
    </div>
  )
}

export default YearSection