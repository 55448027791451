import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';



const BlogPage = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`https://rr-api.realisticrealtors.com/api/rr/category/blog`);
            setData(response.data);
            setLoading(false);
          } catch (error) {
    
            setLoading(false);
          }
        };
    
        fetchData();
      }, []);
      function formatDate(dateString) {
        // Create a Date object from the dateString
        const date = new Date(dateString);
      
        // Define an array of month names
        const monthNames = [
          "January", "February", "March",
          "April", "May", "June", "July",
          "August", "September", "October",
          "November", "December"
        ];
      
        // Get the month, day, and year from the Date object
        const monthIndex = date.getMonth();
        const day = date.getDate();
        const year = date.getFullYear();
      
        // Construct the desired date format
        const formattedDate = `${monthNames[monthIndex]} ${day}, ${year}`;
      
        return formattedDate;
      }

    return (
        <div className='px-[1.5rem]  sm:px-[4.5rem] min-h-screen'>
            <div className="sm:pt-[5rem] pt-[3.5rem]  ">

                <div className="justify-start items-center inline-flex mt-[1.25rem] sm:mt-[2.8rem]">

     
                    <div className="pr-[8px] sm:pr-[12px] border-r border-slate-700 border-opacity-60 justify-start items-center flex ">
                    <Link to="/" className='no-underline'>
                        <div className="text-stone-500 text-sm sm:text-base font-medium font-metropolis leading-snug hover:text-violet-400 cursor-pointer">Home</div>
                        </Link>
                    </div>

                    <div className="px-[8px] sm:px-[12px] border-r border-slate-700 border-opacity-60 justify-start items-center flex ">
                    <Link to="/casestudy" className='no-underline'>
                        <div className="text-stone-500 text-sm sm:text-base font-medium font-metropolis leading-snug hover:text-violet-400 cursor-pointer">Insights</div>
                        </Link>
                    </div>

                    <div className="px-[8px] sm:px-[12px] border-r border-slate-700 border-opacity-60 justify-start items-center flex ">
                    <Link to="/blogs" className='no-underline'>   
                        <div className="text-violet-400 text-sm sm:text-base font-medium font-metropolis leading-snug hover:text-violet-400 cursor-pointer">Blogs</div>
                        </Link>
                    </div>
                </div>
                <div className='sm:my-[1.5rem] my-[21px]'>
                    <div className="text-[#403663] text-[40px] sm:text-5xl font-light font-['Canela'] leading-[48px] sm:leading-[57.60px] sm:mt-[12px]">Realistic Blogs</div>
                </div>


                <div className=' mb-[2.8rem]'>
                    <img className="w-full h-[208px] sm:h-[388px] rounded-xl object-cover hidden sm:block" src="https://d2s80gmgq3vhu4.cloudfront.net/Rectangle+57.png"  />
                    <img className="w-full h-[208px] sm:h-[388px] rounded-xl object-cover sm:hidden" src="https://d2s80gmgq3vhu4.cloudfront.net/Rectangle+57+(1).png"  />
                </div>


            </div>
            <div className='mt-[3.25rem] sm:mt-[6.03rem] sm:flex gap-[3rem] w-full'>
                <div className='sm:w-[70%]'>
{console.log(data && data[0].image)}
                    <img className="w-full object-cover h-[31rem] rounded-xl hidden sm:block" src={data && data[0].image}/>
                    <img className="w-full object-cover h-[11rem] rounded-xl  sm:hidden" src={data && data[0].image} />
                </div>
                <div className='sm:w-[30%] mt-[28px] sm:mt-0 flex flex-col justify-center'>
                    <div className='max-w-[25rem]'>
                        <div className="text-violet-400 text-sm sm:text-base font-medium font-['Metropolis'] leading-snug">Featured Article</div>
                        <div className="w-full text-slate-700 text-[32px] sm:text-[40px] font-light font-['Canela'] leading-[38.40px] sm:leading-[48px] mt-[12px]">{data && data[0].title}</div>
                        <div className="text-neutral-700 text-sm sm:text-base font-medium font-['Metropolis'] mt-[12px]">{formatDate(data && data[0].report_date)} • {data && data[0].blog_type}</div>
                        <div className="w-full text-neutral-700 text-sm sm:text-base font-medium font-['Metropolis'] leading-snug sm:leading-relaxed my-[22px]">
                        <div dangerouslySetInnerHTML={{ __html: ( data && data[0].description.slice(0,200) + "...") }} />
                        </div>
                    </div>

                    <div className='flex items-center gap-[10px]'>
  <Link to={`/blog/${data && data[0].id}`} className="text-slate-700 text-base font-medium font-['Metropolis'] underline uppercase leading-relaxed">Read more</Link>
  <svg xmlns="http://www.w3.org/2000/svg" width="43" height="16" viewBox="0 0 43 16" fill="none">
    <path d="M42.7071 8.7071C43.0976 8.31658 43.0976 7.68341 42.7071 7.29289L36.3431 0.928929C35.9526 0.538405 35.3195 0.538405 34.9289 0.928929C34.5384 1.31945 34.5384 1.95262 34.9289 2.34314L40.5858 8L34.9289 13.6569C34.5384 14.0474 34.5384 14.6805 34.9289 15.0711C35.3195 15.4616 35.9526 15.4616 36.3431 15.0711L42.7071 8.7071ZM8.74228e-08 9L42 9L42 7L-8.74228e-08 7L8.74228e-08 9Z" fill="#A891F5" />
  </svg>
</div>
                </div>
            </div>
<div>

    
      <main>
                <div className=" my-[6rem]  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3  place-items-center justify-center gap-[3.25rem] sm:gap-[5.75rem]">
                    {data && data.map((post , index) => (
                        
                       <Link to={`/blog/${post.id}`} className='no-underline'>
                       <div className="lg:w-[370px] h-[462px] flex-col justify-start items-start gap-6 inline-flex">
                       <img className="lg:w-[370px] h-[264px] rounded-xl" src={post?.image} />
                       <div className="h-[174px] flex-col justify-start items-start gap-3.5 flex">
                           <div className="text-neutral-700 text-sm sm:text-base font-medium font-['Metropolis']">{formatDate(post?.report_date)} • {post?.blog_type}</div>
                           <div className="lg:w-[370px] text-black text-xl sm:text-2xl font-medium font-['Metropolis'] underline leading-normal sm:leading-[28.80px]">{post.title}</div>
                           <div className="lg:w-[370px] text-neutral-700 text-sm sm:text-base font-medium font-['Metropolis'] leading-normal">
                        <div dangerouslySetInnerHTML={{ __html: ( post?.description.slice(0,150) + "...") }} />

                           </div>
                       </div>
                   </div>
                       </Link>
                    ))}
                </div>
            </main>
</div>

        </div>

    );
};

export default BlogPage;