import React, { useEffect } from 'react';
import { gsap } from 'gsap';

import { useGSAP } from "@gsap/react";


const Section1 = () => {
    const isMobile = window.innerWidth <= 1200; 

    const backgroundImageStyle = {
        backgroundImage: !isMobile && "url('https://rr-media-files.s3.ap-south-1.amazonaws.com/Frame+287.png')",
        backgroundSize: isMobile ? '' : '0%',
        height: '100vh', // Fixed height to prevent collapse
    opacity:isMobile ? '1' : '100%'
    };
    
    useGSAP(() => {
     if(!isMobile){


        gsap.to('#image-scale-till-here', {
            backgroundSize: '100%',
            duration: 5,
            scrollTrigger: {
                trigger: '#image-scale-till-here',
                start: 'top top',
                end: 'bottom top',
                scrub: true,
                pin:true
            
            }
        });
     }else{
   
        gsap.to('#image-scale', {
            opacity: 1,
            duration: 5,
            scrollTrigger: {
                trigger: '#image-scale-till-here',
                start: 'top top',
                end: 'bottom top',
                scrub: true,
                pin:true
            }
        });
     }
  
    }, []);

    return (
        <div className='bg-gray-900'>
            <div id='image-scale-till-here'
                className='sm:pt-[5rem] relative bg-gray-900 bg-cover bg-center  bg-no-repeat h-screen overflow-hidden '
                style={backgroundImageStyle}
            >
            <img  src='https://rr-media-files.s3.ap-south-1.amazonaws.com/Frame+287.png' alt='' className='sm:hidden object-cover w-full h-full absolute top-0' id='image-scale' style={{
                opacity:0
            }}/>
             <div className="w-full min-h-screen px-[20px] sm:px-0 relative">
            <div className="w-full pt-[18.4rem] sm:pt-[20rem]">
                <div className="sm:w-[70rem] sm:ml-[6.7rem] text-white text-[2xl] sm:text-[64px] font-light font-['Canela'] leading-normal sm:leading-[64px] ">Celebrating a Quarter-Century of Real Estate Success with Realistic Realtors</div>
            </div>
         
        </div>
            </div>
           
        </div>
    );
};

export default Section1;
