import React, { useState, useEffect } from 'react';
import YearSection from './YearSection';

const GradientSection = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const updateMousePosition = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', updateMousePosition);

    return () => window.removeEventListener('mousemove', updateMousePosition);
  }, []);

  const calculateGradient = () => {
    const distance = Math.sqrt(
      Math.pow(mousePosition.x - window.innerWidth / 2, 2) +
      Math.pow(mousePosition.y - window.innerHeight / 2, 2)
    );
    const maxDistance = Math.sqrt(
      Math.pow(window.innerWidth / 2, 2) +
      Math.pow(window.innerHeight / 3, 2.9)
    );
    const gradientPosition = distance / maxDistance;
    const easedPosition = Math.min(Math.pow(gradientPosition, 0.75), 1);
    return `radial-gradient(circle at ${mousePosition.x}px ${mousePosition.y}px, #75E8B0, transparent ${easedPosition * 100}%)`;
  };

  return (
    <div className="h-screen flex flex-col justify-center px-[42px] sm:px-[72px] " style={{ background: calculateGradient() }}>
    <div className=' flex justify-center items-center'>

<YearSection  from="2004" to="2008" />
</div>
      {/* Your section content here */}
      <div className="sm:w-[553px] sm:h-[124px] flex-col justify-center items-start gap-12 inline-flex">
</div>

<div className="  sm:w-[1009px] sm:h-[361px] text-violet-400 text-5xl sm:text-8xl font-light font-['Canela'] leading-[65  .40px] sm:leading-[124.80px]">Ascending Ambadeep and to other parts of North India</div>
<div className="sm:w-[559px] text-white text-base sm:text-2xl font-normal font-['Metropolis'] leading-tight sm:leading-[31.20px]">From 2004 to 2007, RR achieved significant milestones, expanding its physical presence to the 5th and 4th floors of Ambadeep Building, CP, and inaugurating branches in Chandigarh and Noida. These expansions marked a significant step in reaching out to a broader audience and expanding RR's influence beyond the confines of the capital.</div>


    </div>
  );
};

export default GradientSection;
