import React from 'react'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useGSAP } from "@gsap/react";

const Section3 = () => {
    const isMobile = window.innerWidth <= 1200; 

    const backgroundImageStyle = {
        backgroundImage:  !isMobile &&  "url('https://rr-media-files.s3.ap-south-1.amazonaws.com/image+301.png')",
        backgroundSize: isMobile ? '' : '0%',
        height: '100vh', // Fixed height to prevent collapse
    opacity:isMobile ? '1' : '100%'
        
    };
    useGSAP(() => {
        if(!isMobile){
           

            gsap.to('#section3-background', {
                backgroundSize: '100%',
                duration: 5,
                scrollTrigger: {
                    trigger: '#section3-background',
                    start: 'top top',
                    end: 'bottom top',
                    scrub: true,
                    pin:true
                
                }
            });
        }else{
            gsap.registerPlugin(ScrollTrigger);
            gsap.to('#image-scale-2', {
                opacity: 1,
                duration: 5,
                scrollTrigger: {
                    trigger: '#section3-background',
                    start: 'top top',
                    end: 'bottom top',
                    scrub: true,
                    pin:true
                }
            });
         }
     
    }, []);
  return (
    <div className='px-[14px] sm:px-[4.5rem] relative flex flex-col sm:flex-row py-3 bg-cover bg-right-bottom  bg-no-repeat min-h-screen justify-center  gap-2 sm:h-[35rem]'  
    style={backgroundImageStyle}
    id='section3-background'
    > 
      <img  src='https://rr-media-files.s3.ap-south-1.amazonaws.com/image+301.png' alt='' className='sm:hidden object-cover w-full h-full absolute top-0 left-0' id='image-scale-2' style={{
                opacity:0
            }}/>
                    <div className='flex flex-col sm:flex-row ite sm:items-center relative '>

                        <div className="sm:w-[583px] text-white text-2xl sm:text-6xl font-light font-['Canela'] leading-[31.20px] sm:leading-[78px] relative">Starting from ground up to setting up a new vertical

                        </div>
                    </div>
                    <div className='hidden sm:flex items-end sm:w-[653px] h-full' >
                    <div>

                    </div>
                    </div>


                </div>
  )
}

export default Section3