import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

const Blog = () => {
  const { id } = useParams(); // Get the id from the URL query parameters
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filteredBlog, setFilteredBlog] = useState(null); // Define filteredBlog state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://rr-api.realisticrealtors.com/api/rr/category/blog`);
        setData(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (data) {
      const blog = data.find(blog => blog.id === id);
      setFilteredBlog(blog); // Update filteredBlog state
    }
  }, [data, id]);

  let index;
  if (data) {
      const filteredBlog = data.find(blog => blog.id === id);
      if (filteredBlog && filteredBlog.id === data[0].id) {
          // If filtered blog's ID matches data[0].id, generate a random index
          index = Math.floor(Math.random() * data.length);
      } else {
          // Otherwise, use index 0
          index = 0;
      }
  } else {
      index = 0; // If data is not available, default to index 0
  }

  let selectedBlog = data && data[index];

  function formatDate(dateString) {
    // Create a Date object from the dateString
    const date = new Date(dateString);

    // Define an array of month names
    const monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];

    // Get the month, day, and year from the Date object
    const monthIndex = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();

    // Construct the desired date format
    const formattedDate = `${monthNames[monthIndex]} ${day}, ${year}`;

    return formattedDate;
  }

  useEffect(() => {
    // Scroll to the top of the page when data changes
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [data, selectedBlog]);

  return (
    <div className='min-h-screen blog-page'>
      <div className='  pt-[4rem] sm:pt-[5rem]  flex flex-col-reverse  sm:flex-row gap-[2.25rem] sm:gap-[3rem] w-full'>
        <div className='sm:w-[30%] sm:pl-[4.5rem] pb-[20px] sm:pb-[0]  sm:mt-0 flex flex-col  px-[24px] sm:p-[0] '>

          <div className="justify-start items-center sm:inline-flex mt-[1.25rem] sm:mt-[2.8rem] hidden ">
            <div className="pr-[8px] sm:pr-[12px] border-r border-slate-700 border-opacity-60 justify-start items-center flex ">
            <Link to="/" className='no-underline'>

              <div className="text-stone-500 text-sm sm:text-base font-medium font-metropolis leading-snug hover:text-violet-400 cursor-pointer">Home</div>
            </Link>
            </div>
            <div className="px-[8px] sm:px-[12px] border-r border-slate-700 border-opacity-60 justify-start items-center flex ">
            <Link to="/casestudy" className='no-underline'>
              <div className="text-stone-500 text-sm sm:text-base font-medium font-metropolis leading-snug hover:text-violet-400 cursor-pointer">Insights</div>
              </Link>
            </div>
            <div className="px-[8px] sm:px-[12px] border-r border-slate-700 border-opacity-60 justify-start items-center flex ">
            {/* <Link to="/blogs" className='no-underline'>
              <div className="text-violet-400 text-sm sm:text-base font-medium font-metropolis leading-snug hover:text-violet-400 cursor-pointer">Blogs</div>
              </Link> */}
            </div>
          </div>
          <div className='max-w-[25rem] sm:mt-[2.5rem]'>
            <div className="w-full text-slate-700 text-[32px] sm:text-5xl font-light font-['Canela'] leading-[38.40px] sm:leading-[48px] mt-[12px]">{filteredBlog && filteredBlog.title}</div>
          </div>
          <div className=" mt-[12px] mb-[7.9rem] sm:mb-[0] sm:mt-[14px] w-32 px-[10px]  py-[8px] rounded-[100px]  border-neutral-700 border-2 inline">
            <div className="text-center text-neutral-700 text-xs font-medium font-['Metropolis'] uppercase">{filteredBlog && filteredBlog.blog_type}</div>
          </div>
          <div className='flex flex-col justify-end h-full sm:pb-[2.5rem]'>
            <div className="text-neutral-700 text-base font-medium font-['Metropolis'] leading-snug">{filteredBlog && formatDate(filteredBlog.report_date) }</div>
            <div className="w-[132px] h-12 flex-col justify-start items-start gap-1 inline-flex">
              <div className="text-neutral-700 text-base font-medium font-['Metropolis'] leading-snug">Written By</div>
              <div className="text-neutral-700 text-base font-medium font-['Metropolis'] leading-snug">{filteredBlog && filteredBlog.author}</div>
            </div>
          </div>
        </div>


        <div className='sm:w-[70%]'>
        <div className="justify-start items-center inline-flex sm:hidden my-[1.25rem] sm:mt-[2.8rem] px-[24px] sm:p-[0]  ">
            <div className="pr-[8px] sm:pr-[12px] border-r border-slate-700 border-opacity-60 justify-start items-center flex ">
              <div className="text-stone-500 text-sm sm:text-base font-medium font-metropolis leading-snug hover:text-violet-400 cursor-pointer">Home</div>
            </div>
            <div className="px-[8px] sm:px-[12px] border-r border-slate-700 border-opacity-60 justify-start items-center flex ">
              <div className="text-stone-500 text-sm sm:text-base font-medium font-metropolis leading-snug hover:text-violet-400 cursor-pointer">Insights</div>
            </div>
            <div className="px-[8px] sm:px-[12px] border-r border-slate-700 border-opacity-60 justify-start items-center flex ">
              <div className="text-stone-500 text-sm sm:text-base font-medium font-metropolis leading-snug hover:text-violet-400 cursor-pointer">Blogs</div>
            </div>
          </div>
          <img className="w-full object-cover h-[40rem]  hidden sm:block" src={filteredBlog && filteredBlog.image} />
          <img className="w-full object-cover h-[18rem]   sm:hidden" src={filteredBlog && filteredBlog.image}/>
        </div>
      </div>
      <div className=' sm:py-[7rem] sm:px-[4.5rem] gap-[47px] grid grid-cols-1 sm:grid-cols-2'>
        <div className='py-[2.4rem] px-[2.4rem] sm:py-[0] sm:px-[0]'>
          <div>
            <div className="text-black text-xl font-medium font-['Metropolis'] leading-loose">
            <div dangerouslySetInnerHTML={{ __html: filteredBlog && filteredBlog.description }} />
              </div>

          </div>


        </div>

        <div className='bg-neutral-100 h-fit sm:py-[20px] sm:px-[16px] '>
        <div className='py-[2.4rem] px-[2.4rem] sm:py-[0] sm:px-[0]'>
            <img className="w-full h-[220px] sm:h-[424px] rounded-md object-cover" src={selectedBlog && selectedBlog.image} alt='' />
            <div className='bg-white flex flex-col gap-[14px] py-[24px] px-3 sm:px-0'>
                <div className="sm:w-[623px] text-center text-neutral-700 text-base font-medium font-['Metropolis'] py-[]">{formatDate(selectedBlog && selectedBlog.report_date)} • {selectedBlog && selectedBlog.blog_type}</div>
                <div className="sm:w-[500px] mx-auto text-center text-black text-2xl font-medium font-['Metropolis'] underline leading-[28.80px]">{selectedBlog && selectedBlog.title}</div>
                <div className="sm:w-[623px] text-center text-neutral-700 text-base font-medium font-['Metropolis'] leading-normal">
                    <div dangerouslySetInnerHTML={{ __html: (selectedBlog && selectedBlog.description.slice(0,200) + "...") }} />
                </div>
                <Link to="/blogs">
                    <div className="text-center text-slate-700 text-base font-medium font-['Metropolis'] underline">Read more blogs  </div>
                </Link>
            </div>
        </div>

          <div className='sm:py-[55px]'>

            <div className="w-full justify-center items-center gap-6 flex flex-col sm:inline-flex">
              <div className="h-12 w-[205px] sm:px-8 sm:py-4 bg-violet-400 rounded-[100px] justify-center items-center flex">
              <Link to="/properties" className='no-underline'>

                <div className="text-center no-underline text-white text-base font-medium font-['Metropolis']">List & Find Space</div>
              </Link>
              </div>
              <div className="h-12 px-8 py-4 rounded-[100px] border-2 border-slate-700 justify-center items-center flex">
                <div className="text-center text-slate-700 text-base font-medium font-['Metropolis']">Celebrate 25 years</div>
              </div>
            </div>
          </div>

          <div className="sm:w-[623px] h-[43px] justify-between sm:justify-center px-[24px] sm:px-[0] items-center sm:gap-[56.82px] flex sm:inline-flex my-[24px] sm:my-[0]">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="31" viewBox="0 0 32 31" fill="none">
                <g clip-path="url(#clip0_559_1355)">
                  <path d="M31.433 15.5945C31.433 7.05975 24.5568 0.141357 16.0758 0.141357C7.591 0.143277 0.714844 7.05975 0.714844 15.5964C0.714844 23.3076 6.33172 29.7 13.6724 30.8595V20.0615H9.77556V15.5964H13.6763V12.189C13.6763 8.31711 15.9702 6.17863 19.4774 6.17863C21.159 6.17863 22.9155 6.48002 22.9155 6.48002V10.2809H20.9786C19.0724 10.2809 18.4773 11.473 18.4773 12.6958V15.5945H22.7351L22.0555 20.0596H18.4754V30.8576C25.8161 29.6981 31.433 23.3057 31.433 15.5945Z" fill="#403663" />
                </g>
                <defs>
                  <clipPath id="clip0_559_1355">
                    <rect width="30.7143" height="30.7143" fill="white" transform="translate(0.714844 0.142853)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.32031 5.70893C3.32031 5.02837 3.57715 4.37569 4.03431 3.89446C4.49147 3.41324 5.11152 3.14289 5.75805 3.14289H30.0585C30.3789 3.14234 30.6962 3.20831 30.9924 3.33704C31.2885 3.46576 31.5577 3.65471 31.7843 3.89306C32.011 4.13142 32.1908 4.41449 32.3134 4.72608C32.436 5.03767 32.4991 5.37166 32.4989 5.70893V31.2883C32.4992 31.6257 32.4364 31.9598 32.314 32.2715C32.1915 32.5832 32.0119 32.8665 31.7854 33.1051C31.5588 33.3437 31.2898 33.5329 30.9937 33.662C30.6976 33.791 30.3803 33.8573 30.0598 33.8572H5.75805C5.43781 33.8572 5.12071 33.7907 4.82486 33.6617C4.52902 33.5327 4.26022 33.3435 4.03384 33.1051C3.80746 32.8667 3.62792 32.5836 3.50549 32.2721C3.38306 31.9607 3.32014 31.6268 3.32031 31.2897V5.70893ZM14.8697 14.8534H18.8208V16.942C19.3911 15.7413 20.85 14.6607 23.0424 14.6607C27.2454 14.6607 28.2415 17.0523 28.2415 21.4402V29.5683H23.988V22.4398C23.988 19.9408 23.4177 18.5307 21.9694 18.5307C19.9601 18.5307 19.1245 20.0511 19.1245 22.4398V29.5683H14.8697V14.8534ZM7.57508 29.3771H11.8298V14.6607H7.57508V29.3757V29.3771ZM12.4386 9.86094C12.4466 10.2444 12.3818 10.6257 12.2479 10.9824C12.1141 11.3392 11.9139 11.6642 11.6591 11.9384C11.4043 12.2126 11.1 12.4304 10.7641 12.5792C10.4282 12.7279 10.0675 12.8045 9.70312 12.8045C9.33874 12.8045 8.97802 12.7279 8.64215 12.5792C8.30627 12.4304 8.002 12.2126 7.74719 11.9384C7.49238 11.6642 7.29217 11.3392 7.1583 10.9824C7.02444 10.6257 6.95961 10.2444 6.96763 9.86094C6.98338 9.10823 7.2785 8.39194 7.78979 7.86547C8.30108 7.33899 8.98788 7.04418 9.70312 7.04418C10.4184 7.04418 11.1052 7.33899 11.6165 7.86547C12.1278 8.39194 12.4229 9.10823 12.4386 9.86094Z" fill="#403663" />
              </svg>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="39" height="41" viewBox="0 0 39 41" fill="none">
                <path d="M19.5888 15.3022C16.8368 15.3022 14.591 17.6379 14.591 20.4999C14.591 23.362 16.8368 25.6977 19.5888 25.6977C22.3408 25.6977 24.5866 23.362 24.5866 20.4999C24.5866 17.6379 22.3408 15.3022 19.5888 15.3022ZM34.5785 20.4999C34.5785 18.3475 34.5972 16.2146 34.481 14.0661C34.3648 11.5706 33.8174 9.35582 32.0627 7.53096C30.3043 5.7022 28.1784 5.13681 25.7789 5.01593C23.7093 4.89505 21.6584 4.91455 19.5925 4.91455C17.5229 4.91455 15.4721 4.89505 13.4062 5.01593C11.0066 5.13681 8.87704 5.7061 7.12237 7.53096C5.36394 9.35972 4.82029 11.5706 4.70407 14.0661C4.58784 16.2185 4.60658 18.3514 4.60658 20.4999C4.60658 22.6484 4.58784 24.7853 4.70407 26.9338C4.82029 29.4293 5.36769 31.6441 7.12237 33.4689C8.88079 35.2977 11.0066 35.8631 13.4062 35.984C15.4758 36.1048 17.5267 36.0853 19.5925 36.0853C21.6622 36.0853 23.713 36.1048 25.7789 35.984C28.1784 35.8631 30.308 35.2938 32.0627 33.4689C33.8211 31.6402 34.3648 29.4293 34.481 26.9338C34.601 24.7853 34.5785 22.6523 34.5785 20.4999ZM19.5888 28.4974C15.3333 28.4974 11.899 24.9256 11.899 20.4999C11.899 16.0743 15.3333 12.5025 19.5888 12.5025C23.8442 12.5025 27.2786 16.0743 27.2786 20.4999C27.2786 24.9256 23.8442 28.4974 19.5888 28.4974ZM27.5936 14.0428C26.6 14.0428 25.7976 13.2083 25.7976 12.175C25.7976 11.1417 26.6 10.3072 27.5936 10.3072C28.5871 10.3072 29.3895 11.1417 29.3895 12.175C29.3898 12.4204 29.3435 12.6634 29.2534 12.8901C29.1632 13.1169 29.0309 13.3229 28.8641 13.4964C28.6973 13.6699 28.4992 13.8075 28.2812 13.9012C28.0631 13.995 27.8295 14.0431 27.5936 14.0428Z" fill="#403663" />
              </svg>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="43" viewBox="0 0 44 43" fill="none">
                <g clip-path="url(#clip0_559_1361)">
                  <path d="M42.7068 11.6588C42.4634 10.7902 41.9891 10.0039 41.3344 9.38335C40.6613 8.74373 39.8362 8.2862 38.9372 8.05393C35.5724 7.16706 22.0919 7.16706 22.0919 7.16706C16.472 7.10312 10.8536 7.38434 5.26817 8.00914C4.36909 8.25856 3.54559 8.72632 2.87092 9.37081C2.208 10.0086 1.72783 10.7952 1.477 11.657C0.874451 14.903 0.581691 18.199 0.602668 21.5004C0.581168 24.7988 0.87321 28.0937 1.477 31.3438C1.72246 32.202 2.20083 32.985 2.86554 33.6174C3.53025 34.2499 4.358 34.7068 5.26817 34.9486C8.67771 35.8337 22.0919 35.8337 22.0919 35.8337C27.7189 35.8977 33.3446 35.6165 38.9372 34.9916C39.8362 34.7594 40.6613 34.3018 41.3344 33.6622C41.9888 33.0418 42.4626 32.2554 42.705 31.3868C43.3233 28.142 43.6239 24.8447 43.6027 21.5416C43.6492 18.2245 43.3489 14.9117 42.7068 11.657V11.6588ZM17.8062 27.6351V15.3675L29.0221 21.5022L17.8062 27.6351Z" fill="#403663" />
                </g>
                <defs>
                  <clipPath id="clip0_559_1361">
                    <rect width="43" height="43" fill="white" transform="translate(0.605469)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="23" viewBox="0 0 25 23" fill="none">
<path d="M25 3.28698C24.0806 3.71865 23.093 4.01032 22.0547 4.14215C23.126 3.46291 23.9275 2.39386 24.3097 1.13448C23.3031 1.76797 22.2016 2.21388 21.0528 2.45282C20.2803 1.57882 19.2571 0.999529 18.142 0.804868C17.027 0.610208 15.8825 0.811072 14.8862 1.37628C13.8899 1.94148 13.0976 2.8394 12.6323 3.93064C12.167 5.02187 12.0547 6.24536 12.3128 7.41115C10.2734 7.30264 8.27824 6.74096 6.45691 5.76254C4.63558 4.78412 3.02876 3.41084 1.74073 1.73182C1.30032 2.53682 1.04708 3.47015 1.04708 4.46415C1.04659 5.35898 1.25455 6.2401 1.65251 7.02934C2.05048 7.81858 2.62614 8.49153 3.32842 8.98848C2.51396 8.96102 1.71747 8.72783 1.00524 8.30832V8.37832C1.00516 9.63335 1.41486 10.8498 2.16483 11.8211C2.91479 12.7925 3.95883 13.4591 5.11979 13.7077C4.36425 13.9243 3.57211 13.9562 2.80322 13.801C3.13078 14.8809 3.76882 15.8252 4.62804 16.5017C5.48725 17.1783 6.52462 17.5532 7.59491 17.574C5.77803 19.0853 3.53418 19.9051 1.22435 19.9015C0.815182 19.9016 0.406364 19.8763 0 19.8257C2.34462 21.423 5.07392 22.2708 7.86136 22.2675C17.2972 22.2675 22.4555 13.9865 22.4555 6.80448C22.4555 6.57115 22.45 6.33548 22.4401 6.10215C23.4435 5.33329 24.3096 4.38119 24.9978 3.29048L25 3.28698Z" fill="#403663"/>
</svg>
            </div>
          </div>




        </div>
      </div>
      <div>

        <div className='flex justify-between mx-[24px] items-center sm:mx-[72px] my-[36px] sm:my-[0]'>
          <div className="text-slate-700 text-[32px] font-light font-['Canela']">Related reads</div>
          <div className="text-right text-slate-700 text-xl font-medium font-['Metropolis'] underline">Read all</div>

        </div>

        <main>
     
          <div className="mx-[24px] sm:mx-[72px] mt-[14px] mb-[144px]  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3  place-items-center justify-center gap-[48px] ">
            {data && data.map((post) => (
              <Link to={`/blog/${post.id}`} className='no-underline'>
              <div className="lg:w-full h-full flex-col justify-start items-start gap-6 inline-flex">
                <img className="lg:w-full h-[424px] object-cover rounded-xl" src={post.image} />
                <div className="h-[174px] flex-col justify-start items-start gap-3.5 flex">
                  <div className="text-neutral-700 text-sm sm:text-base font-medium font-['Metropolis']">{formatDate(post?.report_date)} • {post?.blog_type}</div>
                  <div className="lg:w-[370px] text-black text-xl sm:text-2xl font-medium font-['Metropolis'] underline leading-normal sm:leading-[28.80px]">{post.title}</div>
                  <div className="lg:w-[370px] text-neutral-700 text-sm sm:text-base font-medium font-['Metropolis'] leading-normal">

                  <div dangerouslySetInnerHTML={{ __html: ( post?.description.slice(0,150) + "...") }} />
                  </div>
                </div>
              </div>
              </Link>
            ))}
          </div>
        </main>

      </div>
    </div>
  )
}

export default Blog
