import React from 'react'
import { gsap } from 'gsap';

import { useGSAP } from "@gsap/react";
import YearSection from './YearSection';

const Section6 = () => {
    // const isMobile = window.innerWidth <= 1200; 

    useGSAP(() => {

      
            gsap.to('#section-6', {
                opacity: '100%',
                duration: 5,
                scrollTrigger: {
                    trigger: '#section-6',
                    start: 'top top',
                    end: 'bottom top',
                    scrub: true,
                    pin:true
                
                }
            });
        
    
    }, []);
  return (
    <div  style={{
        opacity:0
    }}  id='section-6' >
 <div className=' my-[60px] flex justify-center items-center'>

<YearSection  from="2014" to="2018" />
</div>
    <div className='grid sm:grid-cols-2 px-[32px] sm:px-[0]  sm:w-full mx-auto h-[661px] sm:min-h-screen relative  z-20  '
   
    >
    
    <div className='sm:pl-[106px] mt-[10rem] sm:mt-[0] '>
        <div className=" text-white text-2xl sm:text-[64px] font-light font-['Canela'] leading-[31.20px] sm:leading-[83.20px]"> PAN India Reach And Celebrating Our Annual Spectacle</div>
        <div className="w-[70%] sm:w-full h-[165px] justify-start items-start inline-flex sm:mt-5 mt-[15px]">
            <div className=" text-white text-base sm:text-2xl font-normal font-['Metropolis'] leading-tight sm:leading-[31.20px]">These years (2014-2018) have been instrumental in expanding our reach in India and bringing new opportunities to us.</div>
        </div>
    </div>
    <div className='hidden sm:block'>
        <img className=" h-screen" src="   https://rr-media-files.s3.ap-south-1.amazonaws.com/low-angle-office-building-with-copy-space+2.png" />

    </div>
    <div className='absolute right-[0] bottom-0  sm:hidden z-[-1]'>
    <img src='https://rr-media-files.s3.ap-south-1.amazonaws.com/big-building2.png' />

    </div>
</div>
    </div>
  )
}

export default Section6